import { Link } from "react-router-dom";
import Truck from "../../assets/img/truck.png"
import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux";
import { homeSelector, settingData } from "../../store/reducer/HomeReducer";
import { useEffect } from "react";
const Footer = () => {

    const dispatch = useDispatch();
    const { settingDatas } = useSelector(homeSelector);

    useEffect(() => {
        // if (localStorage.getItem('isAuthenticated'))
        dispatch(settingData({}))
    }, [])
    console.log(settingDatas, 'settingDatas')
    return (
        <div>
            {/* <!-- FOOTER TOP PART START --> */}
            <div class="footer_top">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
                            <div class="footer_widget">
                                <div class="footer_widget_title">
                                    <h2>Contact Us!</h2>
                                </div>
                                <ul class="footer_widget_content">
                                    <li><span>Phone:</span>{settingDatas?.contact}</li>
                                    <li className="email_content"><span>Email:</span>{settingDatas?.gmail}</li>
                                    <li><span>Address:</span>{settingDatas?.address}</li>
                                </ul>

                                {/* <div class="footer_social">
                                    <ul class="footer_social_icons">
                                        <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                        <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                        <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                        <li><a href="#"><i class="icofont-whatsapp"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
                            <div class="footer_widget">
                                <div class="footer_widget_title">
                                    <h2>Customer support</h2>
                                </div>
                                <ul class="footer_widget_content">
                                    <li><i class="icofont-double-right"></i>
                                        <Link to={'/about_us'}>About us</Link>
                                        {/* <a href="#">about us</a> */}
                                    </li>
                                    <li><i class="icofont-double-right"></i>
                                        <Link to={'/privacy_policy'}>privacy policy</Link>
                                    </li>
                                    <li><i class="icofont-double-right"></i><Link to={'/terms_and_conditions'}>Terms & Conditions</Link></li>
                                    <li><i class="icofont-double-right"></i><Link to={'/return_policy'}>Product returns</Link></li>
                                    <li><i class="icofont-double-right"></i><Link to={'/shipping_policy'}>Shipping Policy</Link></li>
                                    <li><i class="icofont-double-right"></i><Link to={'/contact_us'}>Contact</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
                            <div class="footer_widget">
                                <div class="footer_widget_title">
                                    <h2>location</h2>
                                </div>
                                <div>
                                    <iframe class="map-dig" src={settingDatas?.map_url} style={{ height: '200px' }}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FOOTER TOP PART END --> */}

            {/* <!-- FOOTER BOTTOM PART START --> */}
            <div class="footer_bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                            <div class="footer_txt text-center">
                                <p>Copyright {dayjs().year()} <span>Harvest Fresh</span>. All Rights Reserved. Powered by <a href="https://digital-garage.in/" target="_blank">NRJ Digital Garage (P)Ltd.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="truck">
                    <img src={Truck} alt="" />
                </div>
            </div>
            {/* <!-- FOOTER BOTTOM PART END --> */}
        </div>
    )
}


export default Footer;