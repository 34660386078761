import React, { useEffect } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myAccountSchema } from '../utils/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { MyAccountDetails, getUser, homeSelector, clearMessages } from '../store/reducer/HomeReducer';
import { API_STATUS } from '../utils/Constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const MyAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData, myAccountLoader, errorMessage } = useSelector(homeSelector)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(myAccountSchema),
    });

    const submitHandler = formData => {
        console.log(formData, 'Formdata MyAccount accounDetails')
        const ignoreDatas = ['created_at', 'name', 'image', 'created_by', 'updated_at', 'updated_by']
        const ignorekeys = Object.keys(formData)
        for (let index = 0; index < ignorekeys.length; index++) {
            const element = ignorekeys[index];
            if (ignoreDatas.includes((element))) {
                console.log(element)
                delete formData[element]
            }

        }
        let date = formData.dob;
        formData.dob = dayjs(date).format('YYYY-MM-DD')
        dispatch(MyAccountDetails({ formData }))
    }

    useEffect(() => {
        dispatch(getUser());
    }, [])

    console.log(userData, 'userData')

    useEffect(() => {
        reset(userData?.datas)
    }, [userData])

    useEffect(() => {
        if (myAccountLoader === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success('Account Details Updated Successfully')
            dispatch(clearMessages());
        }
    }, [myAccountLoader])

    useEffect(() => {

        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message) toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toast.error(data);
            }
            dispatch(clearMessages());
        }
    }, [errorMessage]);

    return (
        <div>
            <Header />

            <section class="user_dashboard_pages">
                <div class="container">
                    <div class="row innerpage user_dashboard space-between align-flex-start">
                        <div class="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                            <div class="myaccounts_sidebar white_bg">
                                <div class="account_nav">
                                    <ul>
                                        <li>
                                            <Link to={'/my_account'} >My Account</Link>
                                        </li>
                                        <li>
                                            <Link to={'/my_orders'}>My Orders</Link>
                                        </li>
                                        <li><div style={{ cursor: 'pointer' }} onClick={() => {
                                            localStorage.clear();
                                            navigate('/');
                                        }}>Log Out</div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-9 col-xl-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="dashboard_area white_bg">
                                <div class="myaccount_edit d-flex align-center">
                                    <div class="col-md-6 left">
                                        <h3 class="sub_heading">My Account</h3>
                                    </div>
                                    <div class="col-md-6 right">
                                        <a href="#"><img src="img/avatar.webp" alt="" /></a>
                                    </div>
                                </div>

                                <div class="profile_form">
                                    <form onSubmit={handleSubmit(submitHandler)}>
                                        <div class="form_group d-flex">
                                            <div class="col-md-6 form_field">
                                                <label for="first_name">First Name (as per document)</label>
                                                <input type="text" name="first_name" {...register("first_name")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.first_name?.message && (
                                                        <p className="error">{errors.first_name.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col-md-6 form_field">
                                                <label for="lastname">Last Name (as per document)</label>
                                                <input type="text" name="lastname" {...register("last_name")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.last_name?.message && (
                                                        <p className="error">{errors.last_name.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_group d-flex">
                                            <div class="col-md-6 form_field">
                                                <label for="dob">Date Of Birth</label>
                                                <input type="date" name="dob"
                                                    defaultValue={userData?.datas?.dob}
                                                    {...register("dob")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.dob?.message && (
                                                        <p className="error">{errors.dob.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col-md-6 form_field">
                                                <label for="gender">Gender</label>
                                                <select name="gender"  {...register("gender")}>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                    <option value="O">Others</option>
                                                </select>
                                                <div className='mt-2 p-1'>
                                                    {errors?.gender?.message && (
                                                        <p className="error">{errors.gender.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_group d-flex">
                                            <div class="col-md-6 form_field">
                                                <label for="mobile">Phone</label>
                                                <input type="text" name="mobile" {...register("mobile")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.mobile?.message && (
                                                        <p className="error">{errors.mobile.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col-md-6 form_field">
                                                <label for="email">Email</label>
                                                <input type="text" name="email"  {...register("email")} disabled />
                                                <div className='mt-2 p-1'>
                                                    {errors?.email?.message && (
                                                        <p className="error">{errors.email.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_group d-flex">
                                            <div class="col-md-6 form_field">
                                                <label for="city">City</label>
                                                <input type="text" name="city"  {...register("city")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.city?.message && (
                                                        <p className="error">{errors.city.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col-md-6 form_field">
                                                <label for="zipcode">PIN Code</label>
                                                <input type="text" name="zipcode" {...register("zipcode")} />
                                                <div className='mt-2 p-1'>
                                                    {errors?.zipcode?.message && (
                                                        <p className="error">{errors.zipcode.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_group col-full d-flex">
                                            <div class="col-md-12">
                                                <div class="form_field">
                                                    <label for="address">Address</label>
                                                    <textarea name="address" {...register("address")}>

                                                    </textarea>
                                                    <div className='mt-2 p-1'>
                                                        {errors?.address?.message && (
                                                            <p className="error">{errors.address.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_group flex justify-flex-start">
                                            <input type="submit" value="Submit" name="submit" class="submit_btn" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default MyAccount