import React, { useEffect, useRef, useState } from 'react'
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import Banner from './Banner';
import BestSeller from './BestSeller';
import Features from './Features';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial1 from "../../assets/img/veg.jpg"
import news from "../../assets/img/news2.jpg"
import offerImage from "../../assets/img/fruits.png"
import About from "../../assets/img/Healthy-Food-Diet-Transparent.png"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { offerList, productSelector } from '../../store/reducer/ProductReducer';

const Index = () => {
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const dispatch = useDispatch();
    const settings = {
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.testimonial-text',
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            }
        ]
    };
    const { offerData } = useSelector(productSelector);
    const setting = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        arrows: true,
        centerPadding: 0,
        dots: false,
        speed: 1000,
        asNavFor: '.testimonial-img',
        prevArrow: '<i class="icofont-double-right"></i>',
        nextArrow: '<i class="icofont-double-left"></i>',

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    useEffect(() => {
        dispatch(offerList({ id: 1 }))
    }, [])

    return (
        <>
            <Header />
            <Banner />
            <Features />
            {/* <!-- ABOUT US PART START --> */}
            <div class="full-about" id="full-about">
                <div class="container">
                    <div class="row">
                        <div class="about-title">
                            <h2>About us</h2>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="about-content">
                                <h3>Welcome to Harvest Fresh - <span>Where Freshness Meets Your Doorstep!</span></h3>
                                <div class="about-details">
                                    <p>At Harvest Fresh, we've sown the seeds of a vibrant foundation aimed at bringing the farm-to-table experience right to your home.</p>
                                    <p>Our commitment revolves around cultivating the freshest vegetables and fruits, ensuring they journey directly from our farms to your household once a week. What sets us apart is our promise to deliver produce that has been harvested within a mere 48 hours before reaching your doorstep, ensuring you receive the pinnacle of freshness.</p>
                                </div>

                                <div class="about-icon-text align-items-center">
                                    <div class="abt-icon">
                                        <i class="icofont-fruits"></i>
                                    </div>
                                    <div class="abt-text">
                                        <h2>Fresh Look And <span>100% Organic</span> Farm Fresh Produce Right.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="about_img">
                                <img src={About} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ABOUT US PART END --> */}
            <BestSeller />
            {/* <!-- OFFER PART START --> */}
            <div class="full-offer">
                <div class="bg-1"></div>
                <div class="bg-2"></div>
                <div class="container">
                    <div class="offer_promo">
                        <div class="offer-content">
                            <h3>{offerData?.description}</h3>
                            <h2>{offerData?.phrase}</h2>
                            {/* <a href={null} class="btn">Explore more <i class="icofont-bubble-right"></i></a> */}
                            <Link to={'/products'} class="btn">Explore more <i class="icofont-bubble-right"></i></Link>
                        </div>
                        <div class="offer_img">
                            <img src={offerImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- OFFER PART END --> */}
            {/* <!-- TESTIMONIAL PART START --> */}
            <div class="full-testimonial" id="full-testimonial">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="section-title">Benefits of eating Fresh Vegetables</h3>
                            {/* <p class="section-subtitle">The passage experienced a surge in popularity during the 1960s when again during the 90s as desktop publishers</p> */}
                        </div>
                    </div>

                    <div class="form-row align-items-center mt-5">
                        <div class="col-md-4">
                        <img class="w-100" src={Testimonial1} alt="" />
                            {/* <div class="testimonial-img text-center">
                                <Slider {...settings} asNavFor={slider2}
                                    ref={(slider) => setSlider1(slider)}
                                >
                                    <div class="img">
                                        <img class="w-100" src={Testimonial1} alt="" />
                                    </div>

                                    <div class="img">
                                        <img class="w-100" src={Testimonial1} alt="" />
                                    </div>

                                    <div class="img">
                                        <img class="w-100" src={Testimonial1} alt="" />
                                    </div>
                                </Slider>
                            </div> */}
                        </div>
                        <div class="col-md-8">
                            <div class="testimonial-text">
                                    <div class="testimonial-info">
                                        <p>
Embark on a journey of taste and health with our freshly harvested vegetables and fruits. Our produce is packed with essential nutrients and flavors, offering you a delectable palette of nature's best. Consuming fresh vegetables has myriad benefits, from heightened nutritional content to enhanced taste. Harvest Fresh is your gateway to a healthier lifestyle, where every bite is a celebration of freshness and well-being.</p>
                                        </div>

                                {/* <Slider settings={setting} asNavFor={slider1}
                                    className="image-carousel-secondary"
                                    focusOnSelect={true}
                                    ref={(slider) => setSlider2(slider)}>
                                    <div class="testimonial-info">
                                        <p>“ There are many variati ons passages of but the majority have suffered alteration in some form, injected humour randomised words which don't look even slightly believable. If you are going to use a passage of text all the loss.</p>
                                        <h3>Salvador Stanley</h3>
                                        <h4>Happy Customer</h4>
                                    </div>

                                    <div class="testimonial-info">
                                        <p>“ There are many variati ons passages of but the majority have suffered alteration in some form, injected humour randomised words which don't look even slightly believable. If you are going to use a passage of text all the loss.</p>
                                        <h3>Salvador Stanley</h3>
                                        <h4>Happy Customer</h4>
                                    </div>

                                    <div class="testimonial-info">
                                        <p>“ There are many variati ons passages of but the majority have suffered alteration in some form, injected humour randomised words which don't look even slightly believable. If you are going to use a passage of text all the loss.</p>
                                        <h3>Salvador Stanley</h3>
                                        <h4>Happy Customer</h4>
                                    </div>
                                </Slider> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- TESTIMONIAL PART END --> */}
            {/* <!-- CONTACT NOW PART END --> */}
            <div class="contact-now">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="contact-quote">If you Need Natural And Fresh Organic Fruits!</h3>
                            {/* <a href={null} class="btn">Contact Now <i class="icofont-bubble-right"></i></a> */}
                            <Link to={'/contact_us'} class="btn">Contact Now <i class="icofont-bubble-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- CONTACT NOW PART END --> */}
            {/* <div class="full-latest-news">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="section-title">Latest News</h3>
                            <p class="section-subtitle">The passage experienced a surge in popularity during the 1960s when again during the 90s as desktop publishers</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="latest-news-grid">
                                <div class="news-img">
                                    <img class="w-100" src={news1} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage experienced a popularity during the used it on their dry-transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="latest-news-list d-flex">
                                <div class="news-img">
                                    <img class="w-100" src={news} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage popularity dry transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>

                            <div class="latest-news-list d-flex">
                                <div class="news-img">
                                    <img class="w-100" src={news} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage popularity dry transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}
            <Footer />
        </>
    )
};

export default Index