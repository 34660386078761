import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};

export const signUp = postData => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signup", postData);
}
/** Products API */
export const categories = (page, limit, query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/category?query=" + query + "&page=" + page + "&limit=" + limit);
}

export const products = (page, limit, query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product?query=" + query + "&page=" + page + "&limit=" + limit);
}

export const searchFilterProduct = (filterArray) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/product/filters", filterArray);
};


export const banners = (page, limit, query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/banner?query=" + query + "&page=" + page + "&limit=" + limit);
};

export const getProductById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product/" + id);
};

export const productsByCategory = (getData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/product/bycategory", getData);
}

export const offers = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/offer/list/" + id);
};

export const razorPay = (data) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout", data, Http.getAuthToken())
};

export const getSettings = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings", Http.getAuthToken())
}
/** Cart Data */

export const cartData = (page, limit, query) => {
    console.log(page, limit, query, "CAETWD");
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/cart/cartProduct?query=" + query + "&page=" + page + "&limit=" + limit, {}, Http.getAuthToken());
}

export const createcartProduct = (addData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/cart/addCart", addData, Http.getAuthToken());
}

export const delcartProduct = (cartId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/cart/removeCart/" + cartId.id, Http.getAuthToken());
}

export const updateMyaccount = formData => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/update_account", formData, Http.getAuthToken());
}
export const findUserDetails = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/profile/", Http.getAuthToken());
};

export const sendMail = formData => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/contact_us", formData)
}

export const orderList = (query, limit, page) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/orders/list?query=" + query + "&page=" + page + "&limit=" + limit, Http.getAuthToken());
}

export const verifyPayment = data => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout/verification", data, Http.getAuthToken());
}

export const failPayment = (data) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/checkout/fail", data, Http.getAuthToken());
}